import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import ArrowIcon from '@/assets/svg/arrow.svg';

interface LinkProps {
  title: string;
  to: string;
  className?: string;
  alone?: boolean;
  blank?: boolean;
}

const LinkItem: React.FC<LinkProps> = ({
  title,
  to,
  className,
  alone = true,
  blank = false,
}) => {
  const classes = useMemo(
    () =>
      classNames(
        'underline underline-offset-4 decoration-4 decoration-primary flex gap-4 items-center group w-fit text-2xl lg:text-3xl',
        { group: alone },
        className,
      ),
    [alone, className],
  );

  const content = useMemo(
    () => (
      <>
        <span className="group-hover:text-primary group-hover:-translate-x-2 transition-all duration-500 leading-normal">
          {title}
        </span>
        <ArrowIcon
          className="fill-primary group-hover:translate-x-2 transition-all duration-500"
          width={38}
          height={17}
        />
      </>
    ),
    [title],
  );

  if (alone) {
    if (to.startsWith('https://')) {
      return (
        <a
          href={to}
          className={classes}
          target={blank ? '_blank' : ''}
          rel="noreferrer"
        >
          {content}
        </a>
      );
    }
    return (
      <Link to={to} className={classes}>
        {content}
      </Link>
    );
  }

  // Return with no link tag
  return <div className={classes}>{content}</div>;
};

export default LinkItem;
