import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { LAFRAISE_PROJECT } from '@/referentials/routes';

import Layout from '@/components/Layout';
import ContentSection from '@/components/Portfolio/ContentSection';
import SoloPicture from '@/components/Portfolio/SoloPicture';
import NextProject from '@/components/Portfolio/NextProject';
import PortfolioHeader from '@/components/Portfolio/PortfolioHeader';

export default function LMSG({ data }) {
  const { t } = useTranslation();

  const tags = ['ux', 'development', 'acquisition', 'data'];

  return (
    <Layout description={t('description')}>
      <PortfolioHeader
        title={t('title')}
        websites={[t('website')]}
        description={t('description')}
        tags={tags}
      />

      <SoloPicture source={data.cover} alt={t('coverLmsg')} />
      <ContentSection title={t('paragraph1Title')} content={t('paragraph1')} />

      <NextProject title={t('nextProject')} to={LAFRAISE_PROJECT} />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["lmsg", "tags", "general"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    cover: file(relativePath: { eq: "projects/lmsg/lmsg.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
