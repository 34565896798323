import React from 'react';

import LinkItem from '@/components/LinkItem';

interface NextProjectProps {
  title: string;
  to: string;
}

const NextProject: React.FC<NextProjectProps> = ({ title, to }) => (
  <div className="mt-20 mb-[200px] lg:mb-[370px]">
    <LinkItem title={title} to={to} className="font-heading float-right" />
  </div>
);

export default NextProject;
