import React from 'react';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';

interface SoloPictureProps {
  source: ImageDataLike;
  alt: string;
}

const SoloPicture: React.FC<SoloPictureProps> = ({ source, alt }) => (
  <GatsbyImage
    image={getImage(source)}
    alt={alt}
    className="my-2 lg:my-8 w-full rounded-main"
  />
);

export default SoloPicture;
